<template>
    <div class="mw-100 p-2">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Notes</p>
                <hr>
                <div class="input-group" v-if="hasEditPermission">
                  <textarea
                    maxlength="200"
                    rows="1"
                    class="form-control"
                    placeholder="Write notes"
                    style="resize: none; border-radius: 6px 0 0 6px;"
                    v-model="state.notes"
                    :disabled="['CANCELED'].includes(state.status.enum)"
                  >
                  </textarea>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-primary"
                      :style="{'opacity': ['CANCELED'].includes(state.status.enum) ? 0.5 : 1}"
                      type="button"
                      style="border-radius: 0 6px 6px 0;"
                      @click="!['CANCELED'].includes(state.status.enum) && updateNotes()"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="f-14 font-500" v-else>{{ state.notes || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body f-14">
                <p class="f-16 font-600">Booking Information</p>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary align-self-center">Status</div>
                  <div class="col-7 font-500 text-right">
                    <div
                      :class="{ 'btn btn-sm': state.status && state.status.enum }"
                      :style="{
                        'backgroundColor': state.status && state.status.backgroundColor,
                        'color': state.status && state.status.textColor
                      }"
                    >
                      {{ state.status.label || '-' }}
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Booking ID</div>
                  <div class="col-7 font-500 text-right">{{ state.bookingNumber || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Booking Date</div>
                  <div class="col-7 font-500 text-right">
                    {{ state.bookingDate || '-' | moment("DD MMMM YYYY, HH:mm") }}{{ `${state.bookingDate ? ' WIB' : ''}` }}
                  </div>
                </div>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Clinic Name</div>
                  <div class="col-7 font-500 text-right">{{  state.clinicName || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Treatment Date</div>
                  <div class="col-7 font-500 text-right">{{ state.treatmentDate || '-' | moment("DD MMMM YYYY") }}</div>
                </div>
                <div class="row">
                  <div class="col-5 text-secondary">Treatment Time</div>
                  <div class="col-7 font-500 text-right">{{  state.treatmentTime || '-' }}</div>
                </div>
                <div
                  class="mt-1"
                  style="
                    background: rgb(242, 244, 246);
                    border-left: 4px solid rgb(121,142,163);
                    padding: 12px 12px 12px 16px;
                  "
                  v-if="['CANCELED'].includes(state.status.enum)"
                >
                  <div class="font-500">Cancel Reason</div>
                  <div>{{ state.cancelReason || '-'  }}</div>
                </div>
                <hr
                  v-if="
                    hasEditPermission &&
                    state.status.enum &&
                    !['COMPLETED', 'CANCELED'].includes(state.status.enum) &&
                    generateCtaText(state.status.enum) !== ''
                  "
                >
                <button
                  class="btn btn-primary w-100 rounded"
                  v-if="
                    hasEditPermission &&
                    state.status.enum &&
                    !['COMPLETED', 'CANCELED'].includes(state.status.enum) &&
                    generateCtaText(state.status.enum) !== ''
                  "
                  @click="updateStatus(state.status.enum)"
                >
                  {{ generateCtaText(state.status.enum) }}
                </button>
                <!-- Temporary commented out until the task is assigned -->
                <!-- <button class="btn btn-outline-primary mt-1 w-100 rounded">Reschedule</button> -->
                <button
                  v-if="hasEditPermission && ['BOOKED', 'CONFIRMED'].includes(state.status.enum)"
                  class="btn btn-outline-danger mt-1 w-100 rounded"
                  @click="openCancelReasonModal()"
                >
                  Cancel Booking
                </button>
              </div>
            </div>
            <div class="card">
              <div class="card-body f-14">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="f-16 font-600 mb-0" style="flex: 1;">User Information</p>
                  <router-link
                    :to="`/offline-clinic-booking/user/${state.user.id}/edit?source=booking-detail&bookingId=${state.bookingNumber}`"
                    class="d-flex align-items-center cursor-pointer"
                    style="flex: 0 0 auto"
                    v-if="
                      hasEditPermission &&
                      state.user.id &&
                      state.status.enum !== 'CANCELED'
                    "
                  >
                    <img
                      src="/assets/img/icon/edit-icon-blue.svg"
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      class="f-14"
                      style="color: #0072CD; margin-left: 4px;"
                    >
                      Edit
                    </span>
                  </router-link>
                </div>
                <hr>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">User Name</div>
                  <div class="col-7 font-500 text-right">
                    {{ `${state.user.firstName || '-'}${state.user.lastName ? ` ${state.user.lastName}` : ''}` }}
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Phone Number</div>
                  <div class="col-7 font-500 text-right">{{ state.user.mobileNumber || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Email</div>
                  <div class="col-7 font-500 text-right">{{ state.user.email || '-' }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Sex</div>
                  <div class="col-7 font-500 text-right">{{ generateGender(state.user.gender) }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 text-secondary">Identity Card</div>
                  <div class="col-7 font-500 text-right">{{ state.user.idCard || '-' }}</div>
                </div>
                <div class="row">
                  <div class="col-5 text-secondary">EMR Number</div>
                  <div class="col-7 font-500 text-right">{{ state.user.omnicarePatientId || '-' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel reason modal -->
        <modal :name="CANCEL_REASON" :adaptive="true" height="auto" :width="480" style="z-index: 2000">
          <ValidationObserver ref="modalCancelReasonObserver" slim>
            <div class="modal-container w-100">
              <div class="d-flex align-items-center justify-content-between mb-1">
                <p class="f-16 text-black font-600 mb-0">Cancel Booking</p>
                <div
                  class="d-flex align-items-center justify-content-center rounded-circle cursor-pointer"
                  style="width: 24px; height: 24px; background-color: rgb(228, 232, 237);"
                  @click="closeCancelReasonModal()"
                >
                  <i class="fa fa-close" style="color: rgb(17, 24, 32);"></i>
                </div>
              </div>
              <ValidationProvider name="cancel reason" v-slot="{ errors }" rules="required" slim>
                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                  <textarea
                    maxlength="120"
                    rows="4"
                    class="form-control"
                    :class="{ 'validation-warning': errors[0] }"
                    placeholder="Write the reason"
                    v-model="state.cancelReason"
                    style="resize: none;"
                  >
                  </textarea>
                  <div class="f-12 d-flex align-items-center justify-content-between" style="margin-top: 4px;">
                    <div class="text-muted" v-if="errors[0]">{{ errors[0] }}</div>
                    <div
                      style="flex: 1 0 auto;"
                      class="text-right"
                      :class="{ 'text-muted': errors[0] }"
                    >
                        {{ state.cancelReason === null ? 0 : state.cancelReason.length }}/120
                    </div>
                  </div>
                </div>
              </ValidationProvider>
              <button class="btn btn-primary mt-2 rounded w-100" @click="cancelBooking()">Submit Cancellation</button>
            </div>
          </ValidationObserver>
        </modal>
    </div>
</template>
<script>
const CANCEL_REASON ='cancel-reason';

export default {
    data() {
      return {
        CANCEL_REASON,
        state: {
          id: null,
          activities: [],
          bookingDate: null,
          bookingNumber: null,
          cancelReason: null,
          clinicName: null,
          notes: null,
          status: {},
          treatmentDate: null,
          treatmentTime: null,
          user: {},
        },
      }
    },
    computed: {
      permissions() {
        return this.$store.getters.permissions;
      },
      hasViewPermission() {
        return this.permissions.includes('offline_clinic_booking:view');
      },
      hasEditPermission() {
        return this.permissions.includes('offline_clinic_booking:edit');
      },
    },
    beforeDestroy() {
      this.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        getDetail() {
          this.axios.get(`/v2/clinical/offline-clinic-booking/${this.$route.params.id}`)
            .then((res) => {
              const bookingData = res.data.data;
              this.state = bookingData;
              this.$root.$emit('addAdditionalInfo', bookingData.bookingNumber);
            })
        },
        generateGender(genderValue) {
          switch (genderValue) {
            case 'M':
              return 'Male';
            case 'F':
              return 'Female';
            default:
              return '-';
          }
        },
        generateCtaText(enumValue) {
          switch (enumValue) {
            case 'BOOKED':
              return 'Confirm Booking';
            case 'CONFIRMED':
              return 'Check-in';
            default:
              return '';
          }
        },
        updateStatus(enumValue) {
          let newStatus = '';
          switch (enumValue) {
            case 'BOOKED':
              newStatus = 'CONFIRMED';
              break;
            case 'CONFIRMED':
              newStatus = 'CHECKED_IN';
              break;
          }
          this.axios
            .patch(`/v2/clinical/offline-clinic-booking/${this.state.bookingNumber}`, {
              status: newStatus,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(()=> {
              this.getDetail();
            });
        },
        updateNotes() {
          if (!this.state.bookingNumber) return;
          this.axios
            .post('/v2/clinical/offline-clinic-booking/notes', {
              bookingId: this.state.bookingNumber,
              notes: this.state.notes || null,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(()=> {
              this.getDetail();
            });
        },
        async cancelBooking() {
          const isValid = await this.$refs.modalCancelReasonObserver.validate();
          if (isValid) {
            this.axios
            .patch(`/v2/clinical/offline-clinic-booking/${this.state.bookingNumber}`, {
              status: 'CANCELED',
              cancelReason: this.state.cancelReason,
            })
            .then((res) => {
              this.$swal.fire({
                title: res.data.message,
                text: 'Booking updated',
                icon: 'success',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
              });
              this.closeCancelReasonModal();
            })
            .catch((err) => {
              this.$notify({
                type: 'error',
                title: 'Error!',
                text: err.response.data.message,
              });
            })
            .finally(()=> {
              this.getDetail();
            });
          }
        },
        openCancelReasonModal() {
          this.state.cancelReason = null;
          this.$modal.show(CANCEL_REASON);
        },
        closeCancelReasonModal() {
          this.$modal.hide(CANCEL_REASON);
        },
    },
    mounted() {
      this.$moment.locale('en');
      if (this.hasViewPermission || this.hasEditPermission) {
        this.getDetail();
      } else {
        window.location.assign('/offline-clinic-booking');
      }
    },
}
</script>

<style scoped>
  .modal-container{
    overflow: auto;
    padding: 1rem;
    height: 100%;
  }
</style>