import axios from 'axios'
import store from '../store'
import cookies from 'js-cookie';
import { debounce } from "debounce";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.get('accessToken');
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';

const debouncedRefreshToken = debounce((error) => {
    axios.post('/auth/refresh-token', {
        refreshToken: cookies.get('refreshToken')
    })
    .then(response => {
        const { accessToken, refreshToken } = response.data.data;
        cookies.set('accessToken', accessToken);
        cookies.set('refreshToken', refreshToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        window.location.reload(); // the easy way to refetch all requests
        return Promise.resolve();
    })
    .catch(() => {
        store.dispatch('logoutExpiredToken');
        return Promise.reject(error);
    });
}, 1000);

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        return debouncedRefreshToken(error);
    }
    return Promise.reject(error)
})
export default axios
