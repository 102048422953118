<template>
  <div v-click-outside="handleClickOutside" class="dropdown">
    <div class="wrapper" @click.stop="onClickInside">
      <div @click="toggleDropdown" class="dropdown-trigger">
        <slot name="trigger"></slot>
      </div>
      <div
        v-show="show"
        class="dropdown-content"
        :class="`dropdown-content-${positionState}`"
        ref="dropdown-content"
      >
        <slot name="content" :closeDropdown="closeDropdown"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      position: {
        type: String, // 'left' or 'right'
        default: 'left',
      },
      shouldCloseOnClick: {
        type: Boolean,
        default: true,
      },
      shouldStopPropagationOnTrigger: {
        type: Boolean,
        default: true,
      }
    },
    data () {
      return {
        positionState: this.position,
        show: false,
      }
    },
    methods: {
      handleClickOutside() {
        this.$emit('clickOutside');
        this.closeDropdown();
      },
      toggleDropdown(e) {
        if (this.shouldStopPropagationOnTrigger) {
          e.stopPropagation();
        }
        if (!this.show) {
          this.$emit('open');
          // close other dropdowns
          const otherDropdowns = document.getElementsByClassName('dropdown');
          otherDropdowns.forEach(element => {
            element?.__vue__?.closeDropdown();
          })
        }
        this.show = !this.show;
        this.$nextTick(() => {
          // adjust dropdown position when content is invisble (out of viewport)
          const { left, right } = this.$refs['dropdown-content'].getBoundingClientRect();
          if (this.positionState === 'right' & left < 0) {
            this.positionState = 'left';
          } else if (this.positionState === 'left' & right > window.innerWidth) {
            this.positionState = 'right';
          } 
        });
      },
      closeDropdown() {
        this.show = false;
        // reset position
        this.positionState = this.position;
      },
      onClickInside() {
        if (this.shouldCloseOnClick) {
          this.closeDropdown();
        }
      }
    }
  }
</script>

<style scoped>
.wrapper {
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 1000;
  border-radius: 8px;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  padding: 1rem;
  width: max-content;
}

.dropdown-content-left {
  left: 0;
}
.dropdown-content-right {
  right: 0;
}

</style>